 .body {
     padding: 16px 12px;
     display: flex;
     flex: 1;
     flex-direction: row;
     justify-content: flex-start;
     align-items: stretch;
     max-width: 1140px;
     width: 100%;
 }

 .bodyIn {
     display: flex;
     flex-direction: column;
     flex: 1;
     justify-content: flex-start;
     align-items: stretch;
 }