 .logo {
     line-height: 1.4;
     font-size: 16px;
     text-decoration: none;
     height: 26px;
     border: 1px solid #ffffff75;
     border-radius: 5px;
     text-transform: uppercase;
     color: white;
     padding: 0px 4px;
     margin-right: 30px;
 }

 .logo span {
     font-weight: bold;
 }

 .logo:hover {
     color: white;
 }