 .tabsWrap {
     width: 100%;
     display: flex;
     flex-direction: column;
     flex: 1;
     justify-content: flex-start;
     align-items: stretch;
 }

 div[class~="tab-content"] {
     display: flex;
     flex: 1;
     width: 100%;
 }

 div[role="tabpanel"] {
     width: 100%;
 }